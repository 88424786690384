<template>
  <div class="vox">
    <v-card shaped color="grey darken-3" class="vox-card">
      <div class="fill-height px-2 d-flex flex-row justify-space-between align-center" v-if="!voxError">
        <div class="">
            <commands-dialog></commands-dialog>
        </div>
        <div class="d-flex flex-row justify-space-between align-center">

          <transition name="slide-fade" >
            <div class="white--text mr-4" v-if="micOn && !isPlaying && lastSentence == null">
              "I'm listening..."
            </div>
            <div class="grey--text text--lighten-1 mr-4" v-if="!micOn && isPlaying">
              "mic muted..."
            </div>
          </transition>

          <!-- <transition name="slide-fade" mode="out-in"> -->
            <v-chip color="secondary" class="pr-4 mr-4 white--text text-chip" v-if="micOn && !isPlaying && lastSentence !== null">
              {{lastSentence}}
            </v-chip>
          <!-- </transition> -->
          <!-- @click="startOneCommandArtyom" -->
          <v-menu
                transition="slide-y-transition"
                top
                right
              >
                <template v-slot:activator="{ on }">
                  <v-btn
                    fab
                    v-on="on"
                    :color="micOn && voxEnabled ? 'primary' : 'grey'"
                    :depressed="!micOn && !voxEnabled"
                    class="speechButton"
                    >
                  <v-icon v-if="micOn && voxEnabled" color="white">mic</v-icon>
                  <v-icon v-else color="grey darken-2">mic_off</v-icon>
                </v-btn>
                </template>
                <v-list>
                  <v-list-item @click="restartArtyom">
                    <v-list-item-title>
                      <v-icon class="mr-2">replay</v-icon>Restart Microphone</v-list-item-title>
                  </v-list-item>

                  <v-list-item @click="disableArtyom" v-if="voxEnabled">
                    <v-list-item-title>
                      <v-icon class="mr-2">mic_off</v-icon>
                      <span>Turn Mic: OFF</span>
                    </v-list-item-title>
                  </v-list-item>

                  <v-list-item @click="startOneCommandArtyom" v-else>
                    <v-list-item-title>
                      <v-icon class="mr-2" color="primary">mic</v-icon>
                      <span class="primary--text">Turn Mic: ON</span>
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>

        </div>

      </div>
    <!-- <vue-speech :isEnabled="micOn" class="vue-speech" @onTranscriptionEnd="onEnd"/> -->
    <!-- <vue-speech v-if="micOn" class="vue-speech" @onTranscriptionEnd="onEnd" @speechStatus="speechStatusCheck"/> -->
      <div  v-else class="fill-height px-2 d-flex flex-row justify-space-between align-center">
        <span class="white--text">Voice Commands Disabled...</span>
        <v-btn @click="restartArtyom" color="white">Re-enable Voice</v-btn>
      </div>
    </v-card>
  </div>
</template>

<script>
// import commandsDialog from '@/components/shared/commandsDialog'
const commandsDialog = () => import('@/components/shared/commandsDialog.vue')
export default {
  name: "vox",
  props: ['markers', 'currentMarker', 'isPlaying', 'micOn'],
  components:{commandsDialog},
  data(){
    return{
      micControl: false,
      lastSentence: null,
      transcription: null,
      artyom: null,
      speechStatus: null,
      voxError: false,
      voxEnabled: false,
      commands:{
        previous:[
          "previous", "go back", "rewind", "rewind it", "go back a step", "backwards"
        ],
        play:[
          "play", "go", "start", "next", "next step", "continue", "alright", "get started", "keep going", "proceed", "get on with it"
        ],
        skip:[
          "skip", "skip it", "skip this step", "skip this next step", "next step", "move on", "move forward", "move past this one"
        ]
      }
    }
  },
  methods: {
    // onEnd ({ lastSentence, transcription }) {
    //   let vThis = this;
    //   // `lastSentence` is the last sentence before the pause
    //   // `transcription` is the full array of sentences
    //   this.lastSentence = lastSentence;
    //   this.transcription = transcription;
    //   let data = {'lastSentence':lastSentence, 'transcription': transcription }
    //   this.$emit('voxOutput', data)
    //   setTimeout(function(){
    //       vThis.lastSentence = null;
    //   }, 5000);
    // },
    // speechStatusCheck(data){
    //   process.env.NODE_ENV === "development" ? console.log( 'speech status:', data ) : null;
    // },
    setupArtyom(){
      if (Object.prototype.hasOwnProperty.call(window,'webkitSpeechRecognition')) {
        this.artyom = new this.$artyom();
        // process.env.NODE_ENV === "development" ? console.log( this.artyom.speechSupported(), this.artyom.recognizingSupported() ) : null;
        if(this.artyom.speechSupported() && this.artyom.recognizingSupported()){
          process.env.NODE_ENV === "development" ? console.log( this.artyom ) : null;
          this.setupCommands();
          this.commandListen();
          this.startOneCommandArtyom();
          this.speechStatus = true;
        }
        else{
          this.speechStatus = false;
        }
      }
      else{
        return null;
      }
    },
    restartArtyom(){
      let vThis = this;
      console.log('restarting artyom');
      this.artyom.restart();
      vThis.voxError = false;
    },
    disableArtyom(){
      this.artyom.fatality();
      this.voxEnabled = false;
    },
    startOneCommandArtyom(){
      let vThis = this;
      this.artyom.fatality();// use this to stop any of

      vThis.voxError = this.artyom.detectErrors();

      setTimeout(function(){// if you use artyom.fatality , wait 250 ms to initialize again.
           vThis.artyom.initialize({
              lang:"en-US",// A lot of languages are supported. Read the docs !
              continuous:false,// recognize 1 command and stop listening !
              listen:true, // Start recognizing
              debug:true, // Show everything in the console
              speed:1 // talk normally
          }).then(function(){
              vThis.voxEnabled = true;
              process.env.NODE_ENV === "development" ? console.log( 'success!, now listen for commands' ) : null;
          });
      },250);
    },
    setupCommands(){
      let vThis = this;
      var commandGroup = [
          {
              description:"Play the video",
              smart:false, // a Smart command allow you to use wildcard in order to retrieve words that the user should say
              // Ways to trigger the command with the voice
              indexes: vThis.commands.play,
              // Do something when the commands is triggered
              action:function(){
                vThis.$emit('voxCommands', 'play')
              }
          },
          {
              description:"Rewind the video",
              smart:false, // a Smart command allow you to use wildcard in order to retrieve words that the user should say
              // Ways to trigger the command with the voice
              indexes: vThis.commands.previous,
              // Do something when the commands is triggered
              action:function(){
                vThis.$emit('voxCommands', 'previous')
              }
          },
          {
              description:"Skip ahead the video",
              smart:false, // a Smart command allow you to use wildcard in order to retrieve words that the user should say
              // Ways to trigger the command with the voice
              indexes: vThis.commands.skip,
              // Do something when the commands is triggered
              action:function(){
                vThis.$emit('voxCommands', 'skip')
              }
          }

      ];

      vThis.artyom.addCommands(commandGroup);
    },
    commandListen(){
      let vThis = this;
      vThis.lastSentence = null;
      this.artyom.redirectRecognizedTextOutput(function(recognized,isFinal){
        if(isFinal){
            vThis.lastSentence = recognized;
            setTimeout(function(){// i
              vThis.lastSentence = null;
            }, 3000);
            // console.log("Final recognized text: " + recognized);
        }else{
            process.env.NODE_ENV === "development" ? console.log( recognized ) : null;
        }
    });
  },
  },
  watch: {
    micOn:function(val, old){
      process.env.NODE_ENV === "development" ? console.log('watching mic on', val, old, val === true && old === false ) : null;
        if(val === true && old === false && this.voxEnabled){
          this.startOneCommandArtyom()
        }
        else{
          this.artyom.fatality().then(() => {
            process.env.NODE_ENV === "development" ? console.log( 'did artyome just crash!!! ' ) : null;
            // this.voxError = true;
            // console.log("audio succesfully stopped !");
          });
        }
    },
    // voxError:function(val, old){
    //   console.log('vox error val', val, old);
    //   let vThis = this;
    //   if(val === true && old === false){
    //     console.log('restart artyome');
    //     setTimeout(function(){
    //       vThis.restartArtyom();
    //     }, 500);
    //   }
    // }
  },
  mounted(){
    this.micControl = this.micOn;
    this.setupArtyom()
  },
  beforeDestroy(){
    this.disableArtyom();
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.vox{
  position: fixed;
  width: 30vw;
  bottom: 5%;
  right: 2%;
  height: 75px;
  z-index: 99999;
  .vox-card{
    height: 100%;
    width: 100%;
  }

  .vue-speech{
    opacity: 0;
    position: absolute;
  }
  .text-chip{
    // position: absolute;
  }
}

/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active {
  transition: all 1s ease;
  opacity: 1;
}
.slide-fade-leave-active {
  opacity: 1;
  transition: all 0s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(20px);
  opacity: 0;
}
</style>
